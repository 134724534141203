.payments-list {
	background: #292a39;
	margin-bottom: 24px
}

.payments-pagination {
	margin-bottom: 20px;
}

h5.payment-title {
	margin-bottom: 0;
	max-width: 500px;
	overflow: hidden;
	text-wrap: nowrap;
	text-overflow: ellipsis;
	@media only screen and (max-width: 600px) {
		text-wrap: initial;
	}
}

.payment-title-space {
	width: 100%;
}

.payment-title-space .ant-space-item:last-child {
	width: 100%;
	flex: 1;
}

.payment-title-details-wrapper, .payment-title-spacer {
	flex: 1;
	display: flex;
}

.payment-tag-paid {
	background: #3e9812;
}
