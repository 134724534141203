.login-row {
	height: 100%;
	width: 100%;
	position: absolute;
	@media @mobile {
		width: 100%;
	}
	@media @mobile-xs {
		width: 100%;
	}
}

.loading-row {
	height: 100%;
	width: 100%;
	position: absolute;
}

.login-row div.ant-col {
	@media @mobile {
		width: 80%;
	}
	@media @mobile-xs {
		width: 90%;
	}
}

.form-login {
	width: 400px;
	margin: auto;
	@media @mobile {
		width: 100%;
	}
	@media @mobile-xs {
		width: 100%;
	}
}

.login-form-forgot {
	float: right;
}
.ant-col-rtl .login-form-forgot {
	float: left;
}
.login-form-button {
	width: 100%;
}

.login-logo {
	width: 180px;
	margin: auto;
	display: block;
}

div.login-title {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 8px;
}

div.login-title .ant-page-header-heading {
	align-items: center;
}

div.login-title .ant-page-header-heading-left {
	flex-grow: 1;
}

div.login-title span.ant-page-header-heading-extra {
	flex-grow: 0;
}

div.login-title span.ant-page-header-heading-title {
	font-size: 26px;
}

div.login-register {
	width: 100%;
	text-align: center;
	margin-top: 100px;
}

div.login-register .ant-typography {
	margin-bottom: 0;
	font-size: 16px;
}

div.login-register .ant-typography strong {
	color: #ede6dd;
}

.login-terms {
	position: absolute;
	bottom: 0;
	padding-bottom: 10px;
	text-decoration: underline;
}

div.ant-message {
	margin-top: env(safe-area-inset-top);
}
