// Features import
@import "./features/layout/layout.less";
@import "./features/login/login.less";
@import "./features/navigation/navigation.less";
@import "./features/settings/settings.less";
@import "./features/influencers/influencers.less";
@import "./features/onboard/onboarding.less";
@import "./features/dashboard/dashboard.less";
@import "./features/notifications/notifications.less";
@import "./features/rewards/rewards.less";
@import "./features/clients/clients.less";
@import "./features/missions/missions.less";
@import "./features/payments/payments.less";

@highdensity: ~"only screen and (-webkit-min-device-pixel-ratio: 1.5)",
	~"only screen and (min--moz-device-pixel-ratio: 1.5)",
	~"only screen and (-o-min-device-pixel-ratio: 3/2)",
	~"only screen and (min-device-pixel-ratio: 1.5)";
@mobile-xs: ~"only screen and (max-width: 354px)";
@mobile: ~"only screen and (max-width: 559px)";
@tablet: ~"only screen and (min-width: 560px) and (max-width: 949px)";
@desktop: ~"only screen and (min-width: 950px) and (max-width: 1128px)";
@desktop-xl: ~"only screen and (min-width: 1129px)";
@title-font-family: "Centra No2", Roboto, -apple-system, BlinkMacSystemFont,
	"Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif,
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

@font-face {
	font-family: "Centra No2";
	src: url("./fonts/CentraNo2-Bold.woff2") format("woff2"),
		url("./fonts/CentraNo2-Bold.woff") format("woff");
}

html {
	@media @mobile-xs {
		height: 100vh;
	}
	@media @mobile {
		height: 100vh;
	}
	@media @tablet {
		height: 100vh;
	}
}

h1.ant-typography,
ant-typography h1,
h2.ant-typography,
ant-typography h2,
h3.ant-typography,
ant-typography h3,
h4.ant-typography,
ant-typography h4,
h5.ant-typography,
ant-typography h5 {
	font-family: @title-font-family;
}

.desktop {
	display: none;
	@media @desktop {
		display: initial;
	}
	@media @desktop-xl {
		display: initial;
	}
}

.mobile {
	display: initial;
	@media @desktop {
		display: none;
	}
	@media @desktop-xl {
		display: none;
	}
}

.st0,
.st1,
.st2 {
	fill: none;
	stroke: currentColor;
	stroke-width: 1.5;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-miterlimit: 10;
}

.ant-btn-primary,
.ant-btn-primary:active,
.ant-btn-primary:focus {
	color: #252737;
	background: #dfd5c9;
	text-shadow: none;
	border: none;
}

.ant-btn-primary:hover {
	background: #f9f3eb;
	color: #252737;
}

.ant-statistic-content-suffix {
	color: @text-color-secondary;
	font-size: large;
}

.ant-statistic-title {
	margin-bottom: 0;
	font-size: 14px;
	@media @mobile {
		font-size: 12px;
	}
	@media @mobile-xs {
		font-size: 12px;
	}
}

.ant-tooltip-inner {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12), 0 2px 7px rgba(0, 0, 0, 0.24);
}

.ant-card-actions {
	border-radius: 0px 0px 18px 18px;
}

.ant-row {
	margin-bottom: 8px;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-item .anticon + span {
	margin-left: 0;
}

.ant-menu:after,
.ant-menu:before {
	display: none;
}

.ant-checkbox-checked span.ant-checkbox-inner {
	background-color: #252938;
	border-color: #252938;
}

.ant-collapse > .ant-collapse-item > div.ant-collapse-header {
	padding-left: 40px;
	align-items: center;
}

.ant-switch-checked {
	background-color: #acacac;
}

.ant-collapse > .ant-collapse-item > div.ant-collapse-header {
	@media @mobile {
		padding-left: 12px;
	}
	@media @mobile-xs {
		padding-left: 12px;
	}
	@media @tablet {
		padding-left: 12px;
	}
}

.ant-collapse > .ant-collapse-item > div.ant-collapse-header .ant-typography {
	margin-bottom: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
	padding-top: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	color: #303030;
}

.jodit-status-bar-link {
	display: none;
}

.jodit-ui-separator {
	border-right: 1px solid #707070;
}

@primary-color: #EBE3D9;@font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';