.onboarding-content {
	padding-top: 50px;
	@media @mobile {
		padding-top: 100px;
	}
	@media @mobile-xs {
		padding-top: 100px;
	}
}

.onboarding-stepper {
	width: 80%;
	margin: auto;
}

.onboarding-stepper
	.ant-steps-item-process
	> .ant-steps-item-container
	> .ant-steps-item-icon {
	background: transparent;
}

.step-content {
	margin-top: 50px;
}

.onboarding-form {
	width: 300px;
	@media @mobile-xs {
		width: 250px;
	}
}

.onboarding-tags {
	max-width: 600px;
	flex: 1;
}

.onboarding-tags > .ant-space{
	width: 100%;
}

.tag-selected {
	width: 100%;
	padding: 20px;
	background: #21222f;
	border-radius: 12px;
}

.selectable-tag {
	cursor: pointer;
}

.onboarding-datepick, .onboarding-select {
	width: 100%;
}

.information-highlight {
	padding: 6px 12px;
	line-height: 26px;
	height: 23px;
	background: #242735;
	border-radius: 23px;
	font-weight: bold;
}

.reward-prompt {
	width: 100%;
	padding: 20px;
	background: #292a39;
	border-radius: 12px;
}

.reward-prompt .ant-space-item {
	width: 100%;
}
