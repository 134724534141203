.edit-client-form div.ant-space {
	width: 100%;
}

.edit-client-form div.ant-upload-select-picture-card {
	border-radius: 50%;
}

div.ant-upload-select-picture-card img {
	border-radius: 50%;
	height: 100%;
	object-fit: cover;
}

.input-country {
	width: 100%;
}

div.ant-card.contact-card {
	background: linear-gradient(
		to right bottom,
		rgb(0, 127, 255),
		rgb(0, 89, 178) 120%
	);
	border-radius: 8px;
}

div.ant-card.contact-card span.ant-tag {
	background-color: rgba(235, 227, 217, 0.2);
	border: none;
}
