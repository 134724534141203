.missions-stepper {
	width: 80%;
	max-width: 900px;
	margin: auto;
	margin-top: 16px;
}

.missions-stepper
	.ant-steps-item-process
	> .ant-steps-item-container
	> .ant-steps-item-icon {
	background: transparent;
}

.missions-badge {
	display: flex;
}

.missions-badge .ant-badge-dot {
	width: 12px;
	min-width: 12px;
	height: 12px;
}

.client-select, .audience-select, .type-select {
	width: 100%;
}

.mission-form {
	flex: 1;
	width: 100%;
}

.mission-wrapper {
	width: 100%;
}

.mission-tag {
	display: flex;
	border-radius: 20px;
	border: none;
	background: #2d2f38;
	cursor: pointer;
}

span.mission-tag-declined {
	background: #a40000;
}

span.mission-tag-invited {
	background: #3b3d45;
}

.mission-tag .ant-avatar {
	margin-right: 10px;
	margin-left: -7px;
	min-width: 32px;
	min-height: 32px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.task-tag {
	background: #22242c;
	float: right;
}

.task-tag-ongoing {
	background-color: #015f01;
}


.task-tag-overdue {
	background-color: #8c0000;
}

.task-tag-title, h5.task-tag-title {
	width: 100%;
	max-width: 250px;
	margin-bottom: 0;
}

.task-title {
	display: inline-flex;
}

div.task-title-small {
	padding: 0;
	padding-bottom: 0;
}

.task-title-small span.ant-page-header-heading-title {
	font-size: 12px;
	margin-right: 12px;
}

.mission-tasks {
	background: #292a39;
}

.title-tag {
	display: inline-flex;
	margin-left: 10px;
	padding-left:19px;
}

.participants-wrapper {
	width: 100%;
	text-align: left;
}

.participants-wrapper > .ant-space-item:first-child {
	text-align: right;
}

.participants-avatar {
	margin-right: 10px;
}

.participants-tasks {
	background: #262734;
}

.button-accept-mission {
	max-width: 250px;
}

.ant-drawer .ant-timeline-item-head {
	background: none;
}

.metric-rating {
	width: 100%;
	background: #262734;
	padding: 12px;
	border-radius: 8px;
}

.mission-ratings {
	width: 100%;
}

.mission-ratings > .ant-space-item {
	min-width: 165px;
	width: auto;
}

.total-visists {
	line-height: 30px;
	font-size: 14px;
	margin-right: 0;
}

.metrics-wrapper {
	width: 100%;
}


.metrics-title {
	align-items: baseline;
	width: 100%;
	justify-content: space-between;
}

.metrics-title > .ant-space-item {
	width: auto;
}

.reminder-button {
	align-items: baseline;
	width: 100%;
	justify-content: end;
	padding-top: 10px;
}

.description-preview {
	position: relative;
	max-height: 300px; /* Adjust based on how much you want to show initially */
	overflow: hidden;
	padding-bottom: 20px;
}

.description-preview.expanded {
	max-height: none;
}

.description-preview.collapsed::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 250px; /* Height of the fading effect */
	background: linear-gradient(to bottom, transparent, #292a39); /* Adjust color to match your background */
}

.ant-btn-primary.btn-showmore {
	height: auto;
	width: auto;
	position: absolute;
	bottom: 20px;
	z-index: 1;
	min-height: 40px;
	min-width: 140px;
}

.show-more-button {
	display: flex;
	justify-content: center; /* Aligns children (the button) on the main-axis, which is horizontal by default */
	width: 100%; /* Ensures the container takes full width of its parent */
}

.question-wrapper {
	width: 100%;
	padding: 20px;
	background: #262734;
	border-radius: 12px;
}
