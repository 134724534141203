@import "../navigation/navigation.less";

@mobile-content-padding: 24;
@desktop-content-padding: 50;

.content {
	background-color: transparent;
	padding: 0 @mobile-content-padding*1px;
	padding-bottom: calc(@appbar-height*1px + env(safe-area-inset-bottom));
	padding-top: calc(@mobile-header-height*1px + env(safe-area-inset-top));
	@media @desktop {
		padding: 0 @desktop-content-padding*1px;
	}
	@media @desktop-xl {
		padding: 0 @desktop-content-padding*1px;
	}
}

html,
html body {
	background: #252737
		linear-gradient(180deg, transparent 20%, rgba(119, 137, 168, 0.15) 100%) 0%
		0% no-repeat padding-box;
	height: auto;
	min-height: 100%;
}

section.ant-layout {
	background-color: transparent;
	max-width: 1428px;
	margin: auto;
}

span.ant-page-header-heading-title {
	font-family: @title-font-family;
	margin-right: 24px;
	font-size: 30px;
	line-height: normal;
}

div.header-subtitle .ant-page-header-heading-left {
	flex-direction: column;
	align-items: baseline;
}

div.header-subtitle {
	margin-bottom: -94px;
}

.mobile .ant-page-header {
	padding-right: 0;
	padding-left: 0;
	padding-top: 0;
}

div.ant-page-header {
	padding-bottom: 28px;
	@media @mobile-xs {
		padding-right: 0;
		padding-left: 0;
	}
	@media @mobile {
		padding-right: 0;
		padding-left: 0;
	}
	@media @tablet {
		padding-right: 0;
		padding-left: 0;
	}
}

div.ant-page-header.page-title {
	padding-right: 0;
	padding-left: 0;
	@media @mobile-xs {
		padding-top: 0;
	}
	@media @mobile {
		padding-top: 0;
	}
	@media @tablet {
		padding-top: 0;
	}
}

div.ant-page-header.header-small span.ant-page-header-heading-title {
	@media @mobile-xs {
		font-size: 18px;
	}
	@media @mobile {
		font-size: 18px;
	}
}

div.ant-page-header.header-extra-spacer .ant-page-header-heading-extra {
	justify-content: flex-end;
}

div.ant-page-header-heading {
	justify-content: start;
}

span.ant-page-header-heading-extra {
	flex: 1;
	display: flex;
	align-items: center;
}

span.ant-page-header-heading-extra .spacer {
	flex: 1;
}

div.ant-page-header-back-button {
	font-size: 22px;
}

div.ant-drawer-content {
	background-color: @body-background;
}

div.ant-card,
.ant-drawer div.ant-card {
	background-color: @component-background;
	border-radius: 18px;
}

div.ant-card-body {
	padding: 16px;
}

div.ant-menu-submenu-popup {
	display: none;
}

ul.ant-menu-vertical {
	border: 0;
	font-size: 16px;
}

i.ant-menu-submenu-arrow {
	font-size: 20px;
}

.ant-menu-vertical > .ant-menu-submenu > div.ant-menu-submenu-title {
	border-bottom: 1px solid rgba(235, 227, 217, 0.1);
	line-height: 70px;
	height: 70px;
	vertical-align: middle;
	margin: 0;
	padding-left: 6px;
	transition: 0.3s;
	color: @text-color;
}

.ant-menu-vertical > .ant-menu-submenu > div.ant-menu-submenu-title:active {
	background: transparent;
}

.ant-menu-vertical > .ant-menu-submenu > div.ant-menu-submenu-title:hover {
	color: white;
}

.ant-menu-vertical
	> .ant-menu-submenu
	> div.ant-menu-submenu-title
	.ant-menu-submenu-arrow {
	transition: 0.3s;
	opacity: 0;
}

.ant-menu-vertical
	> .ant-menu-submenu
	> div.ant-menu-submenu-title:hover
	.ant-menu-submenu-arrow,
.ant-menu-vertical
	> .ant-menu-submenu-selected
	> div.ant-menu-submenu-title
	.ant-menu-submenu-arrow {
	color: white;
	opacity: 1;
}

li.ant-menu-submenu-active {
	color: unset;
}

ul.ant-menu .ant-menu-submenu:last-child .ant-menu-submenu-title {
	border: 0;
}

.mobile-drawer {
	margin-top: env(safe-area-inset-top);
}

.mobile-drawer .ant-drawer-body {
	padding-bottom: calc(38px + env(safe-area-inset-bottom));
}

.help-affix {
	position: fixed;
	right: 40px;
	bottom: 40px;
	z-index: 2000;
	transition: bottom 0.2s ease-in-out;
	@media @tablet {
		bottom: calc(@appbar-height*1px + env(safe-area-inset-bottom) + 8px);
		right: 8px;
	}
	@media @mobile {
		bottom: calc(@appbar-height*1px + env(safe-area-inset-bottom) + 8px);
		right: 8px;
	}
	@media @mobile-xs {
		bottom: calc(@appbar-height*1px + env(safe-area-inset-bottom) + 8px);
		right: 8px;
	}
}

.help-affix-mobile-full {
	@media @tablet {
		bottom: calc(env(safe-area-inset-bottom) + 8px);
	}
	@media @mobile {
		bottom: calc(env(safe-area-inset-bottom) + 8px);
	}
	@media @mobile-xs {
		bottom: calc(env(safe-area-inset-bottom) + 8px);
	}
}

.help-dropdown {
	color: red;
}
