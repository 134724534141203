@import "../../themes/dark-theme.less";

@appbar-height: 48;
@mobile-header-padding: 18;
@mobile-header-height: @mobile-header-padding*2+40;
@desktop-header-height: 100;

.header-spacer {
	flex: 1 0;
}

.mobile-header {
	position: fixed;
	padding: @mobile-header-padding*1px;
	padding-top: calc(
		@mobile-header-padding*1px - 8px + env(safe-area-inset-top)
	);
	z-index: 10;
	width: 100%;
	height: auto;
	line-height: normal;
	display: flex;
	align-items: center;
	@media @desktop-xl {
		display: none;
	}
	@media @desktop {
		display: none;
	}
}

.desktop-header {
	display: flex;
	height: @desktop-header-height*1px;
	line-height: @desktop-header-height*1px;
	line-height: 100px;
	@media @tablet {
		display: none;
	}
	@media @mobile {
		display: none;
	}
	@media @mobile-xs {
		display: none;
	}
}
.desktop-header-logo {
	height: 100px;
	padding: 0 24px 0 0px;
}

.mobile-header-logo {
	height: 50px;
}

.mobile-appbar {
	position: fixed;
	z-index: 1;
	width: 100%;
	bottom: 0;
	padding-right: 0;
	padding-left: 0;
	padding-bottom: env(safe-area-inset-bottom);
	height: @appbar-height*1px;
	line-height: @appbar-height*1px;
	box-sizing: content-box;
	@media @desktop-xl {
		display: none;
	}
	@media @desktop {
		display: none;
	}
}

.mobile-appbar ul {
	padding-right: 10px;
	padding-left: 10px;
	display: flex;
	align-items: stretch;
	justify-content: space-around;
}

.mobile-appbar ul.ant-menu.ant-menu-dark {
	background: @mobile-appbar-color;
}

.mobile-appbar ul li {
	display: block;
	flex: 0 1 auto;
}

.menu {
	text-align: center;
	width: 100%;
	background-color: transparent;
	@media @desktop-xl {
		width: auto;
		text-align: right;
	}
	@media @desktop {
		width: auto;
		text-align: right;
	}
}

.ant-btn-icon-only.ant-btn-lg.menu-icon > *,
.ant-menu-item .anticon,
.ant-badge.ant-menu-item-icon {
	font-size: 22px;
	vertical-align: middle;
	margin-right: 0px;
}

.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
	background-color: transparent;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected .anticon .st0 {
	@media @tablet {
		stroke-width: 2.5;
	}
	@media @mobile {
		stroke-width: 2.5;
	}
	@media @mobile-xs {
		stroke-width: 2.5;
	}
}

.ant-menu.ant-menu-dark .ant-menu-item-selected .anticon {
	@media @desktop-xl {
		border-bottom: 1px solid #ebe3d9;
		padding-bottom: 6px;
		padding-top: 7px;
	}
	@media @desktop {
		border-bottom: 1px solid #ebe3d9;
		padding-bottom: 6px;
		padding-top: 7px;
	}
}

.ant-badge.ant-menu-item-icon {
	color: inherit;
}

.ant-menu.ant-menu-dark
	.ant-menu-item-selected
	.ant-badge.ant-menu-item-icon
	.anticon {
	@media @desktop-xl {
		padding-bottom: 5px;
	}
	@media @desktop {
		padding-bottom: 5px;
	}
}

.ant-menu.ant-menu-dark .ant-menu-item .ant-badge-count {
	box-shadow: 0 0 0 2px @body-background;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected .ant-badge-count {
	@media @desktop-xl {
		top: 6px;
	}
	@media @desktop {
		top: 6px;
	}
}

.ant-menu-item .anticon.anticon-notification {
	margin-right: 0;
}

.ant-tabs.tab-nav > div.ant-tabs-nav {
	margin-bottom: 0;
	@media @desktop {
		padding-left: 36px;
	}
	@media @desktop-xl {
		padding-left: 36px;
	}
}

div.ant-tabs-tab {
	color: @disabled-color;
}
