.influencer-card {
	margin: auto;
}

.influencer-card .ant-card-body {
	padding-bottom: 0;
}

.influencer-details .ant-drawer-content-wrapper {
	width: 720px;
	@media @mobile-xs {
		width: 100%;
	}
	@media @mobile {
		width: 100%;
	}
	@media @tablet {
		width: 100%;
	}
}

.influencer-tab {
	width: 100%;
}

.influencer-waiting-rate {
	font-size: 12px;
}

.influencer-waiting-rate .ant-rate-star:not(:last-child) {
	margin-right: 4px;
}

.influencer-active-rating {
		font-size: 18px;
		color: #d4d18e;
		@media @mobile-xs {
			font-size: 14px;
		}
		@media @mobile {
			font-size: 14px;
		}
}

div.influencer-title,
div.influencer-title-small,
div.influencer-title-medium,
div.card-title {
	padding: 0;
}

div.card-title {
	margin-bottom: 12px;
}

div.influencer-title-small span.ant-page-header-heading-title {
	font-size: 16px;
	margin-right: 12px;
}

div.influencer-title-medium span.ant-page-header-heading-title {
	font-size: 20px;
	margin-right: 42px;
}

div.influencer-title span.ant-page-header-heading-title {
	font-size: 20px;
	margin-right: 12px;
}

div.card-title span.ant-page-header-heading-title {
	font-size: 18px;
}
div.card-title div.ant-page-header-heading-left {
	align-items: flex-start;
	flex-direction: column;
}

div.ant-card.regular-card {
	background: #21222f;
	border: none;
	border-radius: 12px;
}

.influencer-tab-side {
	height: 100%;
}

.influencer-tab .ant-result {
	padding: 16px;
}
.influencer-tab .ant-result-icon {
	margin-bottom: 4px;
}

.influencer-tab .ant-result-icon > .anticon {
	font-size: 42px;
}

.small-stat .ant-statistic-content,
.small-stat-string .ant-statistic-content {
	font-size: 18px;
}

.small-stat-string .ant-statistic-content .ant-statistic-content-value {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.global-rate {
	background: #2b2e3f;
	padding: 3px;
	text-align: center;
	border-radius: 4px;
	max-width: 130px;
}

.btn-profile {
	width: 100%;
}

.influencer-card .ant-card-actions > li {
	margin: 0px 0px;
}

.influencer-card .ant-card-actions > li:first-child .ant-btn-primary,
.ant-btn-primary.btn-accept {
	width: 100%;
	height: 100%;
	min-height: 50px;
	border-radius: 0px 0px 0px 18px;
	background: transparent linear-gradient(270deg, #72b7c9 0%, #58c3b7 100%) 0%
		0% no-repeat padding-box;
	border: none;
}

.influencer-card .ant-card-actions > li:first-child .ant-btn-primary:hover,
.ant-btn-primary.btn-accept:hover {
	background: transparent linear-gradient(270deg, #67abbc 0%, #4db1a5 100%) 0%
		0% no-repeat padding-box;
}

.influencer-card .ant-card-actions > li:last-child .ant-btn-primary,
.ant-btn-primary.btn-reject {
	width: 100%;
	height: 100%;
	min-height: 50px;
	border-radius: 0px 0px 18px 0px;
	background: transparent linear-gradient(270deg, #c37d88 0%, #dd644b 100%) 0%
		0% no-repeat padding-box;
	border: none;
}

.influencer-card .ant-card-actions > li:last-child .ant-btn-primary:hover,
.ant-btn-primary.btn-reject:hover {
	background: transparent linear-gradient(270deg, #b26d77 0%, #c75741 100%) 0%
		0% no-repeat padding-box;
}

.ant-btn-primary.btn-reject,
.ant-btn-primary.btn-accept {
	border-radius: 40px;
	min-width: 150px;
}

.influencer-info {
	display: flex;
}

.influencer-info-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	padding: 10px 14px;
	width: 46px;
	height: 46px;
	background: linear-gradient(270deg, #72b7c9 0, #56c3b7);
	color: #252838;
	border-radius: 50%;
	margin-right: -23px;
	z-index: 1;
}

.influencer-info-data {
	padding: 10px 18px;
	padding-left: 37px;
	line-height: 26px;
	height: 46px;
	background: #2b2e3f;
	border-radius: 0px 46px 46px 0px;
}

.influencer-info-tag {
	margin-left: 8px;
	margin-right: -2px
}

.mobile-24 {
	@media @mobile {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}
}

// Audit report

.audit-rate {
	padding: 3px;
	text-align: center;
	border-radius: 4px;
	max-width: 130px;
}

.audit-rate span.ant-statistic-content-value {
	@media @mobile {
		font-size: 16px;
	}
}

.rate-high {
	background: #289155;
}

.audit-tag {
	font-size: 14px;
	@media @mobile {
		font-size: 12px;
	}
}

div.search-card {
	display: flex;
	border-radius: 6px;
	transition: all 0.5s ease-out;
	min-height: 125px;
	overflow: hidden;
}

div.search-card.selected {
	background: linear-gradient(
		to right bottom,
		rgb(0, 127, 255),
		rgb(0, 89, 178) 120%
	);
}

.search-card:hover {
	box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 20px 0px;
}

.search-card .ant-card-body {
	width: 100%;
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-card .ant-card-cover img {
	border-radius: 6px 0px 0px 6px;
	max-height: auto;
	width: 80px;
	height: 125px;
	object-fit: cover;
}

.search-card-rating {
	font-size: 12px;
	color: #d4d18e;
}

.search-card div.ant-card-meta-title {
	line-height: 10px;
}

.search-card span.ant-tag {
	background-color: rgb(204, 203, 203);
	color: rgb(99, 99, 99);
	border: none;
	border-radius: 40px;
	margin-right: 0px;
}

.search-card span.ant-tag .anticon .st0 {
	fill: rgb(99, 99, 99);
}

.search-wrapper {
	width: 100%;
}

div.filter-card {
	background-color: #2d2f38;
}

.filter-card .ant-space-vertical,
.filter-card .ant-space-item, .filter-space {
	width: 100%;
}

.search-input {
	max-width: 400px;
	width: 100%;
}
.search-wrapper div.ant-divider-horizontal {
	margin-top: 0;
}

.search-card div.ant-divider-horizontal {
	margin-top: 0;
	margin-bottom: 5px;
}

.search-wrapper .search-card:hover {
	box-shadow: none;
}

div.search-filter-title {
	margin-bottom: 12px;
}

div.filter-interests {
	max-height: 200px;
  overflow-y: scroll;
}

.search-wrapper span.count-tag {
	font-size: 10px;
	background-color: #252938;
	height: 15px;
	line-height: 15px;
}

span.date-tag {
	font-size: 14px;
	background-color: #252938;
	height: 32px;
	line-height: 32px;
}
.ant-space-item:has(.date-tag) {
	flex: 1;
	text-align: right;
}

div.ant-card.metrics-card {
	background: linear-gradient(
		to right bottom,
		rgb(0, 127, 255),
		rgb(0, 89, 178) 120%
	);
	border-radius: 8px;
}

div.ant-card.metrics-card span.ant-tag {
	background-color: rgba(235, 227, 217, 0.2);
	border: none;
}

div.ant-card.metrics-card span.ant-tag .st0 {
	fill: currentColor;
}

.metrics-title .ant-page-header-heading-left {
	flex-direction: column;
}

.metrics-title .ant-page-header-heading {
	justify-content: center;
}

.audience-action {
	width: 100%;
}

.audience-influencer {
	display: flex;
	border-radius: 20px;
	border: none;
	background: #2d2f38;
}

.audience-influencer .ant-avatar {
	margin-left: -10px;
	margin-right: 10px;
	min-width: 32px;
}

.audience-influencer .anticon-close {
	line-height: 32px;
	padding-right: 5px;
}

.influencer-card-content {
	width: 100%;
	margin: 18px 0px;
}

.influencer-confirm-modal {
	z-index: 2000;
}

.influencer-actions, .influencer-actions .ant-space-item{
	width: 100%;
	padding-top: 16px
}

.total-influencers {
	line-height: 30px;
	font-size: 14px;
}
