div.profile-info {
	flex: 0 1 328px;
	@media @mobile-xs {
		flex: 0 1 100%;
	}
	@media @mobile {
		flex: 0 1 100%;
	}
	@media @tablet {
		flex: 0 1 100%;
	}
}

h1.ant-typography.influencer-title {
	margin-top: 0;
	margin-bottom: 0;
}

.influencer-header {
	padding-top: 104px;
}

.header-color,
h1.ant-typography.header-color,
.header-color .st0 {
	fill: inherit;
	z-index: 2;
	position: relative;
}

div.ant-space-item {
	color: inherit;
}

.influencer-avatar {
	position: absolute;
	width: 220px;
	height: 280px;
	line-height: 280px;
	background-color: rgba(235, 227, 217, 0.05);
	top: 0;
	right: 0;
	border-radius: unset;
	border-radius: 18px;
}

span.influencer-avatar.ant-avatar.ant-avatar-icon {
	font-size: 100px;
}

.header-stats {
	background: #3a3d4e;
	width: 100%;
	padding: 15px;
	margin-top: 18px;
	border-radius: 20px;
	align-items: center;
}

.header-stat {
	text-align: center;
}

.header-level {
	margin-bottom: 12px;
}

.dashboard-content {
	padding: 0 0px;
	flex: 1;
	@media @desktop-xl {
		padding: 0 24px;
	}
	@media @desktop {
		padding: 0 24px;
	}
	@media @tablet {
		padding: 0 24px;
	}
}

.mission-card-completed {
	opacity: 0.6;
}

.mission-card:hover {
	box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 20px 0px;
}

img.mission-cover {
	max-height: 100px;
	object-fit: cover;
	border-radius: 18px 18px 0 0;
}
