.ant-card.settings-menu .ant-card-body {
	padding-top: 0;
	padding-bottom: 0;
}

.ant-card.settings-menu {
	@media @desktop {
		min-width: 327px;
	}
	@media @desktop-xl {
		min-width: 327px;
	}
}

.settings-menu-wrapper {
	text-align: center;
}

button.ant-btn-primary.button-disconnect {
	width: 80%;
	margin-top: 16px;
	min-height: auto;
}

.ant-menu-submenu-vertical .ant-menu-submenu-title .anticon {
	font-size: 16px;
}

.ant-menu-submenu-vertical .ant-menu-submenu-title .anticon .st0,
.ant-menu-submenu-vertical .ant-menu-submenu-title .anticon .st1,
.ant-menu-submenu-vertical .ant-menu-submenu-title .anticon .st2 {
	stroke-width: 2;
}

.settings-wrapper,  .settings-wrapper > .ant-space-item{
	width: 100%;
}

.settings-wrapper {
	@media @desktop {
		padding-left: 24px;
	}
	@media @desktop-xl {
		padding-left: 24px;
	}
}

.settings-section {
	width: 100%;
	padding: 20px;
	background: #242533;
	border-radius: 12px;
}

.settings-section-space {
	width: 100%;
}

.settings-input{
	width: 100%;
	max-width: 300px;
	min-width: 250px;
}

.settings-section-button {
	width: 100%;
	height: 32px;
	text-align: left;
	display: flex;
  justify-content: flex-end;
}

.settings-version {
	padding-top: 20px;
	color: rgb(160 160 160 / 85%)
}
