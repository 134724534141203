.ant-card.rewards-menu {
	@media @desktop {
		width: 100%;
	}
	@media @desktop-xl {
		width: 100%;
	}
}

.rewards-table > div {
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
	padding: 10px;
	@media @mobile {
		padding-left: 0;
		padding-right: 0;
	}
	@media @mobile-xs {
		padding-left: 0;
		padding-right: 0;
	}
}

.rewards-table > div:last-child {
	border-bottom-width: 0px;
}
