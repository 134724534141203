.notifications-drawer .ant-drawer-content-wrapper {
	width: 520px;
	@media @mobile-xs {
		width: 100%;
	}
	@media @mobile {
		width: 100%;
	}
	@media @tablet {
		width: 100%;
	}
}

.notifications-wrapper, .notification-badge {
	width: 100%;
}

.ant-badge-status-dot.ant-badge-status-processing {
	height: 15px;
	width: 15px;
}

.notification-divider {
	margin-top: 0px;
	margin-bottom: 0px;
}

.notification-container {
	width: 100%;
	min-height: 116px;
	padding: 20px;
	background: #292a39;
	border-radius: 8px;
	cursor: pointer;
	transition: 0.5s;
}

.notification-container:hover {
	background: rgba(255, 255, 255, 0.05);
}

h5.notification-title {
	margin-bottom: 0px;
}

.notification-time {
	padding-top: 8px;
	opacity: 0.7;
}


.notification-badge .ant-badge-dot {
	width: 12px;
	min-width: 12px;
	height: 12px;
	transform: translate(40%, -40%);
}
